<template>
<!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
    <div class="section">
        <ul class="titBox">
            <li v-for="(item,index) in state.titList" :key="index" :class="state.curId == item.id ? 'cur' : ''" @click="tabClick(item.id)">{{item.title}}</li>
        </ul>
        <!-- 发展历程 -->
        <div v-if="state.curId == 33" class="devBox">
            <ul>
                <li v-for="(item,index) in state.devNav" :key="index" @click="devNavtab(item.id)">
                    <span :class="state.curDevNavId == item.id?'cur': ''">{{item.name}}</span>
                    <img src="@/assets/images/devCur.png" v-if="state.curDevNavId == item.id" />
                    <img src="@/assets/images/devNoemal.png" v-else />
                </li>
            </ul>
            <div class="devContent">
                <div class="item" v-for="(item,index) in state.devList" :key="index">
                    <div class="itemTop">
                        <div class="time animate__animated animate__bounceInLeft wow">{{item.name}}</div>
                        <div class="circle animate__animated animate__backInUp wow">
                            <div class="circle1"></div>
                        </div>
                        <div class="content animate__animated animate__bounceInRight wow" :class="item.jump_url1 == 1 ? 'cur' : ''">{{item.describe}}</div>
                    </div>
                    <div class="line animate__animated animate__backInUp wow"></div>
                </div>
            </div>
        </div>
        <!-- 其他 -->
        <div class="info animate__animated animate__fadeInUp wow" v-else>
            <img :src="state.contact" v-if="state.curId ==38" />
            <div v-html="state.info"></div>
        </div>
    </div>
</template>
<style  lang="scss" scoped>
.section{
    width: 12rem;
    margin: .5rem auto 1rem;
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 5px 3px rgba(0,0,0,.05);
    .titBox{
        display: flex;
        justify-content: space-between;
        font-size: .18rem;
        margin: 0 .2rem;
        border-bottom: 1px solid #ededed;
        li{
            flex: 1;
            text-align: center;
            line-height: .6rem;
            color: #666;
            cursor: pointer;
        }
        li.cur{
            border-bottom: 1px solid #ffaa00;
            color: #ffaa00;
        }
    }
    
    .info{
        font-size: .16rem;
        line-height: .3rem;
        padding: .5rem;
        color: #666;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img{
            max-width: 100%!important;
            margin: 0 .5rem 0 0;
        }
    }
    // 发展历程
    .devBox{
        padding-bottom: 1.5rem;
        ul{
            display: flex;
            justify-content: center;
            margin: .2rem 0;
            li{
                display: flex;
                flex-direction: column;
                font-size: .18rem;
                cursor: pointer;
                span{
                    display: block;
                    text-align: center;
                    color: #333;
                    font-size: .17rem;
                    line-height: .4rem;
                    margin-bottom: .1rem;
                }
                span.cur{
                    color: #E67F11;
                }
                img{
                    width: 1.68rem;
                    height: .18rem;
                }
            }
        }
        .devContent{
            background: url(../../assets/images/aboutusbg.png) no-repeat;
            background-size: contain;
            background-position: center center;
            padding: 0 1.5rem;
            margin-top: 1rem;
            .item{
                .itemTop{
                    display: flex;
                    .time{
                        font-size: .14rem;
                        width: 1.12rem;
                    }
                    .circle{
                        width: .2rem;
                        height: .2rem;
                        background: #F7D8B7;
                        border-radius: 50%;
                        .circle1{
                            width: .12rem;
                            height: .12rem;
                            background: #E67F11;
                            border-radius: 50%;
                            margin: .04rem;
                        }
                    }
                    .content{
                        font-size: .2rem;
                        height: .3rem;
                        margin-left: .3rem;
                        flex: 1;
                    }
                    .cur{
                        color: #E67F11;
                    }
                }
                .line{
                    width: .02rem;
                    height: 1rem;
                    background: #E67F11;
                    margin-left: 1.21rem;
                    margin-top: -.2rem;
                }
            }
            .item:last-child{
                .line{
                    display: none;
                }
            }
        }
    }
}
</style>
<script>
import {onMounted,reactive,watchEffect} from "vue";
import { useRoute,useRouter } from 'vue-router'
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import { getbannerData,getAboutUsNav,getAboutUsData,getDevelopcontent,getDevelop} from "../../api/request"
export default {
    components: {
        Banner
    },
    setup(){
        const route = useRoute()
        const router = useRouter();
        onMounted(()=>{
            getNavData()
            // getData(state.curId) 
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 50,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            getBanner()
            getDevNav()
        })
        // const titList=["企业简介","来村荣誉","领导关怀","知识产权","技术框架","发展历程","联系我们"]
        let state = reactive({
            bannerArr:[],
            titList:[],
            curId:null,
            info:"",
            contact:"",
            devNav:[],
            curDevNavId:null,
            devList:[]
        })
        // 导航数据
        function getNavData(){
            getAboutUsNav().then((res) => {
                // console.log('导航',res)
                if(res.code == 200){
                    state.titList = res.data
                }
            })
        }
        function getData(id){
            if(id == 33){
                getDev(state.curDevNavId)
            }else{
                getAboutUsData({id:id}).then((res) => {
                    console.log('数据',res)
                    if(res.code == 200){
                        if(id == 38){
                            state.contact=res.data.photo
                        }
                        state.info = res.data.content.replace(/<img/g,"<img style='max-width:100%;height:auto;margin:.1rem auto;display: block;'");
                        // state.info = res.data.content
                    }
                })
            }
        }
        // 年份切换
        function devNavtab(id){
            state.curDevNavId = id 
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 50,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
        }
        // 发展历程年分列表
        function getDevNav(){
            getDevelop().then((res) => {
                // console.log('导航',res)
                if(res.code==200){
                    state.devNav = res.data.title
                    state.curDevNavId = state.devNav[0].id
                }
            })
        }
        // 发展历程
        function getDev(id){
            getDevelopcontent({id:id}).then((res) => {
                console.log('数据',res)
                if(res.code == 200){
                    state.devList = res.data
                }
            })
        }
        // banner
        function getBanner(){
            getbannerData({id:39}).then((res)=>{
                // console.log(res)
                if(res.code == 200){
                    state.bannerArr = res.data
                }
            })
        }
        function tabClick(id){
            state.curId = id
            router.push({
                //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
                name:"",
                query:{
                    id:id,
                }
            })
        }
        watchEffect(()=>{
            state.curId =  route.query.id
            getData(route.query.id) 
            
            window.scrollTo(0,0);
        })
        return{
            state,
            getNavData,
            getData,
            getBanner,
            getDevNav,
            getDev,
            devNavtab,
            tabClick
        }
    }
}
</script>